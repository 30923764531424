import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const FirstVisitPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_img = data.section1_img?.childImageSharp?.fluid
  const section1_background = data.section1_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/first-visit/" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>First Visit endodontics pc, Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="First Visit endodontics pc, Indianapolis IN"/>
        <meta name="description" content="First Visit to Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="og:description" content="First Visit to Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/first-visit/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/first-visit/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="First Visit endodontics pc, Indianapolis IN"/>
        <meta name="twitter:description" content="First Visit to Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="first-visit-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="hero background image"
      >
        <div id="first-visit-hero-section">
          <Container>
            <div className="col-lg-5 pb-5">
              <h1 className="primary-text hero-heading">
                First Visit
              </h1>
              <p className="hero-text">
                Your initial appointment will consist of a consultation explaining your diagnosis and treatment options.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-7">
              <Img fluid={hero_img} className="hero-img" alt="lady wearing yellow shirt"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <BackgroundImage
        Tag="div"
        className="first-visit-section1-bg"
        fluid={section1_background}
        alt="hero background image"
      >
      <section id="first-visit-section-1">
        <Container>
          <div className="col-lg-12">
            <p>Your appointment will include a consultation, so you can learn about your diagnosis and what kind of treatment is available. If the medical history or plan for care is complex, scheduling an evaluation and a second appointment could be necessary to treat any potential complication that may arise adequately.</p>
          </div>
          <div className="col-lg-12 container-box">
            <h5>To assist the office with your consultation, please bring the following items to your first appointment with Meridian Endodontics:</h5>
            <div className="row">
              <div className="col-lg-9">
                <ul>
                  <li>Referral from your dentist</li>
                  <li>Medication list (prescriptions, over-the-counter medications, supplements, and vitamins)</li>
                  <li>Dental and medical insurance information</li>
                  <li>Patient registration form, completed</li>
                  <li>HIPAA form signed</li>
                </ul>
              </div>
              <div className="col-lg-3 image-container">
                <Img fluid={section1_img} className="section1-img"/>
              </div>
            </div>
          </div>
          <div className="col-lg-12 m-t-50">
            <h5>Patients Under 18:</h5>
            <p>A parent or guardian must accompany all minors.</p>
            <h5 id="m-t-10">Patients with Medical Conditions</h5>
            <p>If you have any medical conditions or concerns that might play a role in any treatments or surgery, please alert the office. These ailments include:</p>
            <ul className="pt-0 pl-5">
              <li>High blood pressure</li>
              <li>Diabetes</li>
              <li>Artificial heart valves</li>
              <li>Rheumatic fever</li>
            </ul>
            <p>
              Additionally we need to know about any specific medications that may require special precautions with certain treatments or procedures, such as:
            </p>
            <ul className="pt-0 pl-5">
              <li>Heart medication</li>
              <li>Blood thinners</li>
              <li>Aspirin</li>
            </ul>
            <p>
              Also, patients that require antibiotics before dental cleanings must advise the office before their visit.
            </p>
          </div>
          <div className="col-lg-12 m-t-50 text-center container-box">
            <h2 className="primary-text">X-Rays</h2>
            <p>
              To help the doctors during the consultation, please bring any x-rays from the last six months. You can request to pick them up or have your dental office forward them to Meridian Endodontics. If your x-rays are older than six months or more are necessary, we can take them in our office using the latest technology in radiology that reduces exposure to radiation by 90 percent compared to non-digital x-ray equipment.
            </p>
          </div>
        </Container>
      </section>
    </BackgroundImage>
      <section id="first-visit-section-2">
        <Container>
          <ContactForm />
        </Container>
      </section>
    </Layout>
  )
}

export default FirstVisitPage

export const query = graphql`
  query FirstVisitPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "first-visit-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 991, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_img: file(name: { eq: "first-visit-img2" }) {
      childImageSharp {
        fluid(maxWidth:300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_background: file(name: { eq: "first-visitbg" }) {
      childImageSharp {
        fluid(maxWidth:900, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
